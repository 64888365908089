// Get the element with the ID 'playerCount'
const playerCount = document.getElementById('playerCount');
// Get all elements with the class 'player-name'
const playerNames = document.querySelectorAll('.player-name');
// Get all elements with the class 'player-header'
const playerHeaders = document.querySelectorAll('.player-header');
// Get all rows in the score table except those with the class 'total-row'
const scoreRows = document.querySelectorAll('.score-table tr:not(.total-row)');
// Get the row with the class 'total-row'
const totalRow = document.querySelector('.total-row');
const scoreTable = document.querySelector('.score-table tbody');

// Function to update the display of player-related elements based on the player count
function updatePlayerCount() {
  // Parse the player count value as an integer
  const count = parseInt(playerCount.value);
  // Loop through each player name input and set its display based on the player count
  playerNames.forEach((input, index) => {
    input.style.display = index < count ? 'block' : 'none';
  });
  // Loop through each player header and set its display based on the player count
  playerHeaders.forEach((header, index) => {
    header.style.display = index < count ? '' : 'none';
  });
  // Loop through each score row and update the display of its cells based on the player count
  scoreRows.forEach(row => {
    const cells = row.querySelectorAll('td:not(:first-child)');
    cells.forEach((cell, index) => {
      cell.style.display = index < count ? '' : 'none';
    });
  });
  // Update the display of the cells in the total row based on the player count
  const totalCells = totalRow.querySelectorAll('td:not(:first-child)');
  totalCells.forEach((cell, index) => {
    cell.style.display = index < count ? '' : 'none';
  });
}

// Function to update the player headers with the names from the input fields
function updatePlayerNames() {
  // Loop through each player name input and update the corresponding player header
  playerNames.forEach((input, index) => {
    const name = input.value || `Player ${index + 1}`;
    playerHeaders[index].textContent = name;
  });
}

// Add an event listener to the player count input to call updatePlayerCount when it changes
playerCount.addEventListener('change', updatePlayerCount);
// Add an event listener to each player name input to call updatePlayerNames when it changes
playerNames.forEach(input => {
  input.addEventListener('input', updatePlayerNames);
});

// Initial call to update the player count display
updatePlayerCount();
// Initial call to update the player names display
updatePlayerNames();

//// Logic of the score table

// Array to store player scores
let playerScores = [];
let newRowCreated = false;

// Initialize scores for each player
function initializeScores() {
  const count = parseInt(playerCount.value);
  playerScores = Array(count).fill(0);
  updateTotalDisplay();
}

// Function to add a new row for the next turn
function addNewTurnRow() {
  const row = document.createElement('tr');
  const moveCell = document.createElement('td');
  moveCell.textContent = scoreTable.rows.length; // Move number

  row.appendChild(moveCell);

  for (let i = 0; i < playerScores.length; i++) {
    const cell = document.createElement('td');
    const input = document.createElement('input');
    input.type = 'number';
    input.min = 0; // Ensure no negative scores
    input.addEventListener('input', handleScoreInput);
    cell.appendChild(input);
    row.appendChild(cell);
  }

  scoreTable.insertBefore(row, totalRow);
  newRowCreated = false; // Reset flag for the new row
}

// Function to update totals
function updateTotalDisplay() {
  const totalCells = totalRow.querySelectorAll('td:not(:first-child)');
  totalCells.forEach((cell, index) => {
    cell.textContent = playerScores[index];
  });
}

// Event handler for score input
function handleScoreInput(event) {
  const input = event.target;
  const value = parseInt(input.value) || 0;

  // Get the column index of the input field
  const columnIndex =
    Array.from(input.parentNode.parentNode.children).indexOf(input.parentNode) -
    1;

  // Adjust player's total score
  const previousValue = parseInt(input.getAttribute('data-previous')) || 0;
  playerScores[columnIndex] += value - previousValue;

  // Store the current value as the previous value for next change
  input.setAttribute('data-previous', value);

  // Check if this input is in the last cell of the row
  const parentRow = input.parentNode.parentNode;
  const inputsInRow = Array.from(parentRow.querySelectorAll('input'));
  const isLastInput = inputsInRow.indexOf(input) === inputsInRow.length - 1;

  // Only add a new row if this is the first character in the last input of the row
  // and a new row hasn't been created yet
  if (
    isLastInput &&
    !newRowCreated &&
    input.getAttribute('data-first-entry') !== 'true'
  ) {
    newRowCreated = true; // Set the flag to true to prevent multiple rows being added
    input.setAttribute('data-first-entry', 'true'); // Mark this input as already triggering a row creation
    addNewTurnRow(); // Add new row
  }

  updateTotalDisplay();
}

// Add event listener for changes in player count
playerCount.addEventListener('change', () => {
  updatePlayerCount();
  initializeScores();
  clearScoreTable(); // Clear previous rows except total
  addNewTurnRow(); // Start with one row
});

// Function to clear all score rows except total row
function clearScoreTable() {
  const rows = Array.from(scoreTable.querySelectorAll('tr:not(.total-row)'));
  rows.forEach(row => row.remove());
}
// Get the 'New Game' button element
const newGameButton = document.querySelector('.new-game');

// Function to reset the game
function resetGame() {
  // Show confirmation alert before resetting the game
  const userConfirmed = confirm(
    'Are you ready to start fresh? All current progress will be erased!'
  );

  if (userConfirmed) {
    // Reset player names
    playerNames.forEach(input => (input.value = ''));

    // Reset player scores
    playerScores = Array(parseInt(playerCount.value)).fill(0);
    updateTotalDisplay();

    // Clear the score table except for the total row
    clearScoreTable();
    addNewTurnRow(); // Start with the initial row

    // Update player headers
    updatePlayerNames();

    // Reset any other necessary game state here
  }
}

// Add click event listener to 'New Game' button
newGameButton.addEventListener('click', resetGame);

// Initialize the app
initializeScores();
clearScoreTable();
addNewTurnRow(); // Initial row for turn 1

// Confirmation before exit
window.onbeforeunload = function () {
  return 'Are you sure you want to leave? Your progress will be lost.';
};

// Initial display updates
updatePlayerCount();
updatePlayerNames();
